import pari from './pari.jpeg';
import './App.css';

function App() {
  return (
    <div className="App"
      style={{
        "display": "flex",
      }}
    >
      <div>
        <div
          className="links"
        >
          <a href="https://pari.fhionnghaile.ie">
            Pari's website
          </a>
        </div>
        <div
          className="links"
        >         
          <a href="https://sim.fhionnghaile.ie">
            Particle Simulator
          </a>
        </div>
        <div
          className="links"
        >    
          <a href="https://goals.fhionnghaile.ie">
            Excercise Goals
          </a>
        </div>
      </div>
      <div className="App-header">
        <img src={pari} className="App-logo" alt="logo" />
        <p>
          Love you, my dear!
        </p>
      </div>
    </div>
  );
}

export default App;
